export const AppConfig={
  //loca:"http://192.168.0.100:8082",
 loca: "https://apis.loomyarn.com",
  
  //  loca: "http://192.168.0.103:8082", /*Abdulhaleem*/
 // loca: "http://192.168.0.101:8082",
 // loca:"http://192.168.0.114:8082",
 //loca:"http://localhost:8082", 
 jwtLoca:"http://localhost:8080"
};



