import React, { Component } from "react";
import axios from "axios";
import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import "../css/OtpVerification.css";
import WithNavigate from "./withNavigate";
import WorkInProgress from "./work_in_progress";
import { message } from "antd";

class OtpVerification extends Component {
  state = {
    otp: false,
    time: 0,
    first: "",
    second: "",
    third: "",
    fourth: "",
    page_error: false,
    error: "",
    page_message: false,
    message: "",
    minutes: 1,
    seconds: 59,
    loca: this.props.loca,
    isMobile: this.props.isMobile,
    register: localStorage.getItem("register"),
    isResent: false,
    btn_disable: false,
    loading: false,
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.GenerateNewpinfn = this.GenerateNewpinfn.bind(this);
    this.forgetpasscall = this.forgetpasscall.bind(this);
    this.clearinput = this.clearinput.bind(this);
    this.resendOTP = this.resendOTP.bind(this);
    this.timer = this.timer.bind(this);
    this.fieldverify = this.fieldverify.bind(this);
    this.inputRefs = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      page_message: true,
      message: "Otp has been sent to on your mail",
    });
    console.log(this.props.location);
    this.timer();
  }

  handleKeyDown = (index, e) => {
    const { key, target } = e;

    if (key === "Backspace" && !target.value && index > 0) {
      this.setState(
        (prevState) => {
          const newState = { ...prevState };
          newState[this.getStateKey(index - 1)] = ""; // Clear the previous input
          return newState;
        },
        () => {
          this.inputRefs[index - 1].current.focus();
        }
      );
    } else if (
      key !== "Backspace" &&
      index < this.inputRefs.length - 1 &&
      target.value !== ""
    ) {
      this.setState(
        (prevState) => {
          const newState = { ...prevState };
          newState[this.getStateKey(index)] = target.value;
          return newState;
        },
        () => {
          this.inputRefs[index + 1].current.focus();
        }
      );
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.isMobile !== state.isMobile) {
      return {
        isMobile: props.isMobile,
      };
    }
    return null;
  }

  componentDidUpdate() {
    this.timer();
    if (this.state.second === 1) {
      this.setState({ page_message: false, message: "" });
    }
  }

  componentWillUnmount() {
    // this.setState({ isMounted: false });
    this._isMounted = false;
  }

  forgetpasscall() {
    // this.props.showForgetPassword();
    this.props.navigate("/generatenewpin");
  }

  GenerateNewpinfn() {
    this.setState({ btn_disable: true, page_message: false, message:"" });
    var first = this.state.first;
    var second = this.state.second;
    var third = this.state.third;
    var fourth = this.state.fourth;
    var otp = this.state.otp;

    let o = first + second + third + fourth;
    if (o !== "") {
      let email = localStorage.getItem("email");
      if (email !== "" || email !== null) {
        if (otp === true) {
          if (this.state.register === "false") {
            let fp = { forget_password: { username: email, otp: o } };
            axios.post(this.state.loca + "/loom/set/matchOtp", fp, {}).then(
              (resp) => {
                let optresp = resp.data;
                if ("Error" in optresp) {
                  this.setState({
                    page_error: true,
                    error: optresp.Error,
                    page_message: false,
                    message: "",
                    btn_disable: false,
                  });
                } else {
                  this.setState({
                    page_message: true,
                    message: optresp.Success,
                    page_error: false,
                    error: "",
                    btn_disable: false,
                  });
                  //  this.props.showGenerateNewPin();
                  this.props.navigate("/generatenewpin");
                }
              },
              (error) => {
                this.props.showErrorCompo();
              }
            );
          } else {
            let mobile = localStorage.getItem("mobile");
            let tab_name = localStorage.getItem("tableName");
            let fp = {
              forget_password: { mobile: mobile, otp: o, table_name: tab_name },
            };
            axios
              .post(
                this.state.loca + "/loom/set/mobile/matchOtp/external",
                fp,
                {}
              )
              .then((resp) => {
                let optresp = resp.data;
                if ("Error" in optresp) {
                  this.setState({
                    page_error: true,
                    error: optresp.Error,
                    btn_disable: false,
                  });
                } else {
                  this.setState({
                    page_message: true,
                    message: optresp.Success,
                    page_error: false,
                    error: "",
                    btn_disable: false,
                  });
                  setTimeout(() => {
                    this.setState({ loading: true });
                    // if (this.state.loading === true) {
                    setTimeout(() => {
                      const { navigateWithSearchParams } = this.props;
                      navigateWithSearchParams(
                        "/login",
                        {},
                        { registered: true }
                      );
                    }, 600);
                  }, 700);
                  // }
                }
              });
          }
        } else {
          this.setState({
            page_error: true,
            error: "OTP not match.",
          });
        }
      } else {
        this.setState({
          page_error: true,
          error: "Email id not found...",
        });
      }
    } else {
      this.setState({
        page_error: true,
        error: "Enter OTP...",
        btn_disable: false,
      });
    }
  }

  resendOTP(otp) {  
    this.setState({ isResent: true });
    if (otp === true) {
      if (this.state.register === "false") {
        let email = localStorage.getItem("email");
        let fp = { resend_otp: { username: email } };
        axios.post(this.state.loca + "/loom/set/resend/otp", fp, {}).then(
          (resp) => {
            let optresp = resp.data;
            if ("Error" in optresp) {
              this.setState({
                page_error: true,
                error: optresp.Error,
                page_message: false,
                message: "",
                isResent: false,
                btn_disable: false,
              });
            } else {
              this.setState({
                page_message: true,
                message: optresp.Message,
                page_error: false,
                error: "",
                minutes: 1,
                seconds: 59,
                first: "",
                second: "",
                third: "",
                fourth: "",
                isResent: false,
                btn_disable: false,
              });
            }
          },
          (error) => {
            this.props.showErrorCompo();
          }
        );
      } else {
        let mobile = localStorage.getItem("mobile");
        let tab_name = localStorage.getItem("tableName");
        let fp = { resend_otp: { mobile: mobile, table_name: tab_name } };
        axios
          .post(this.state.loca + "/loom/set/resend/otp/external", fp, {})
          .then(
            (resp) => {
              let optresp = resp.data;
              if ("Error" in optresp) {
                this.setState({
                  page_error: true,
                  error: optresp.Error,
                  page_message: false,
                  message: "",
                  isResent: false,
                  btn_disable: false,
                });
              } else {
                this.setState({
                  minutes: 1,
                  seconds: 59,
                  first: "",
                  second: "",
                  third: "",
                  fourth: "",
                  page_message: true,
                  message: optresp.Message,
                  page_error: false,
                  error: "",
                  isResent: false,
                  btn_disable: false,
                });
              }
            },
            (error) => {
              this.props.showErrorCompo();
            }
          );
      }
    }
  }

  clearinput(n) {
    switch (n) {
      case 1:
        this.setState({ first: "" });
        break;
      case 2:
        this.setState({ second: "" });
        break;
      case 3:
        this.setState({ third: "" });
        break;
      case 4:
        this.setState({ fourth: "" });
        break;
      default:
        this.setState({
          page_error: true,
          error: "Error in int",
          page_message: false,
          message: "",
        });
        break;
    }
  }
  fieldverifyy = (value, index) => {
    if (/^[0-9]*$/.test(value)) {
      this.setState({
        otp: true,
        page_message: false,
        message: "",
        page_error: false,
        error: "",
      });
      this.setState({ [this.getStateKey(index)]: value });
    }
  };

  getStateKey = (index) => {
    switch (index) {
      case 0:
        return "first";
      case 1:
        return "second";
      case 2:
        return "third";
      case 3:
        return "fourth";
      default:
        return "";
    }
  };

  fieldverify(e, n) {
    n = n + 1;
    if (/\d/.test(e)) {
      this.setState({
        otp: true,
        page_message: false,
        message: "",
        page_error: false,
        error: "",
      });
      switch (n) {
        case 1:
          this.setState({ first: e });
          /*       if (e.length === 1) {
            document.getElementById("secondinp").focus();
          } */
          break;
        case 2:
          this.setState({ second: e });
          /*      if (e.length === 1) {
            document.getElementById("thirdinp").focus();
          } */
          break;
        case 3:
          this.setState({ third: e });
          /*      if (e.length === 1) {
            document.getElementById("fourthinp").focus();
          } */
          break;
        case 4:
          if (e.length === 1) {
            this.setState({ fourth: e });
          }
          break;
        default:
          this.setState({
            page_error: true,
            error: "Error in int",
            page_message: false,
            message: "",
          });
          break;
      }
      return "verified";
    } else {
      this.setState({ otp: false });
      return "unverified";
    }
  }

  // timer() {
  //   var time = this.state.time;
  //   for (let i = 0; i < 31; i++) {
  //     setTimeout(() => {
  //       this.setTime(time + 1);
  //     }, 1000);
  //   }
  // }

  timer() {
    var min = this.state.minutes;
    var sec = this.state.seconds;
    const interval = setInterval(() => {
      if (sec > 0) {
        clearInterval(interval);
        if (this._isMounted) {
          this.setState({ seconds: sec - 1 });
        }
      }

      if (sec === 0) {
        if (min === 0) {
          clearInterval(interval);
        } else {
          clearInterval(interval);
          this.setState({ seconds: 59, minutes: min - 1 });
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }

  setTime() {
    this.setState({ time: 0 });
  }

  render() {
    return (
      <div>
        {this.state.loading === true ? (
          <WorkInProgress flag={true}></WorkInProgress>
        ) : (
          <div className="container  login_container">
            <div
              style={{ flexGrow: 1, alignItems: "center", height: "100vh" }}
              className="d-flex justify-content-center"
            >
              <Card
                className="carrd"
                style={{
                  background: "grey",
                  // marginTop: "120px",
                  borderRadius: "5px",
                }}
              >
                <CardHeader style={{ background: "grey" }}>
                  <h3>OTP Verification</h3>
                </CardHeader>

                <CardBody className="cardchild" style={{ background: "grey" }}>
                  {this.state.page_error === true && (
                    <div
                      className="alertgp alert-danger"
                      style={{ color: "black", width: "193px", margin: "auto" }}
                    >
                      {this.state.error}
                    </div>
                  )}

                  {this.state.page_message === true && (
                    <div
                      className="alertgp alert-success"
                      style={{ color: "black" }}
                    >
                      {this.state.message}
                    </div>
                  )}
                  <div style={{ textAlign: "center", padding: "4px" }}></div>
                  {/*          <div className="lom_td">
              <input
                id="firstinp"
                className="otpinp"
                type="int"
                value={this.state.first}
                onChange={(e) => this.fieldverify(e.target.value, 1)}
                onKeyUp={(e) => {
                  if (this.state.first.length === 1 && e.key === "Backspace") {
                    this.clearinput(1);
                  }
                }}
              />
              <input
                id="secondinp"
                className="otpinp"
                type="int"
                value={this.state.second}
                maxLength={1}
                onChange={(e) => this.fieldverify(e.target.value, 2)}
                onKeyUp={(e) => {
                  
                  if (this.state.second.length && e.key === "Backspace") {
                    this.clearinput(2);
                  }
                }}
              />
              <input
                id="thirdinp"
                className="otpinp"
                type="int"
                value={this.state.third}
                onChange={(e) => this.fieldverify(e.target.value, 3)}
                onKeyUp={(e) => {
                  if (this.state.third.length === 1 && e.key === "Backspace") {
                    this.clearinput(3);
                  }
                }}
              />
              <input
                id="fourthinp"
                className="otpinp"
                type="int"
                value={this.state.fourth}
                onChange={(e) => this.fieldverify(e.target.value, 4)}
                onKeyUp={(e) => {
                 
                  if (this.state.fourth.length === 1 && e.key === "Backspace") {
                    this.clearinput(4);
                  }
                }}

              />
            </div> */}

                  {[0, 1, 2, 3].map((index) => (
                    <input
                      key={index}
                      type="tel"
                      maxLength="1"
                      onKeyDown={(e) => this.handleKeyDown(index, e)}
                      value={
                        index === 0
                          ? this.state.first
                          : index === 1
                          ? this.state.second
                          : index === 2
                          ? this.state.third
                          : this.state.fourth
                      }
                      ref={this.inputRefs[index]}
                      // style={{ width: '30px', marginRight: '5px' }}
                      className="otpinp"
                      onChange={(e) => this.fieldverifyy(e.target.value, index)}
                    />
                  ))}

                  <div className="countdown-text">
                    {this.state.seconds > 0 || this.state.minutes > 0 ? (
                      <p style={{ color: "white", paddingTop:"4px" }}>
                        Time Remaining:{" "}
                        {this.state.minutes < 10
                          ? `0${this.state.minutes}`
                          : this.state.minutes}
                        :
                        {this.state.seconds < 10
                          ? `0${this.state.seconds}`
                          : this.state.seconds}
                      </p>
                    ) : (
                      <p style={{ color: "white" }}>Didn't recieve code?</p>
                    )}

                    {/*          <button
                disabled={
                 ( this.state.seconds > 0 ||
                  this.state.minutes > 0 ) ||
                  this.state.isResent
                }
                style={{ backgroundColor: "#ffc312" }}
                onClick={(e) => this.resendOTP(true)}
              >
                Resend OTP
              </button> */}

                    <div className="form-group" style={{ marginTop: "22px" }}>
                      <input
                        disabled={
                          this.state.seconds > 0 ||
                          this.state.minutes > 0 ||
                          this.state.isResent
                        }
                        type="button"
                        value="Resend OTP"
                        className="btn btnyellow btn-hover"
                        onClick={(e) => this.resendOTP(true)}
                        style={{
                          backgroundColor: "#ffc312",
                          fontWeight: "500",
                        }}
                      ></input>
                    </div>
                  </div>
                  {/* <div className="form-group">
              <input
                type="button"
                value="Resend OTP"
                className="btn-hover"
                onClick={(e) => this.resendOTP(true)}
              ></input>
            </div> */}
                  <div className="form-group" style={{ marginTop: "22px" }}>
                    <input
                      disabled={this.state.btn_disable === true}
                      type="button"
                      value="Verify"
                      className="btn btnyellow btn-hover"
                      onClick={(e) => this.GenerateNewpinfn(e.target.value)}
                      style={{ backgroundColor: "#ffc312", fontWeight: "500" }}
                    ></input>
                  </div>
                </CardBody>

                {/*           <CardFooter style={{ background: "grey" }}>
                <div className="d-flex links">
                  <a
                    href="/#"
                    onClick={this.forgetpasscall}
                    style={{ color: "black" }}
                  >
                    <i
                      className="fa fa-arrow-left"
                      style={{ color: "black", marginTop: "6px" }}
                    ></i>{" "}
                    Back
                  </a>
                </div>
              </CardFooter> */}
              </Card>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WithNavigate(OtpVerification);
