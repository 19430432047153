import React, { Component } from "react";
import axios from "axios";
import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import "../css/OtpVerification.css";
import "../cssMod/OtpVerification.module.css";
import WithNavigate from "./withNavigate";
import validator from "validator";

class GenerateNewPin extends Component {
  state = {
    password: "",
    confirmPassword: "",
    error: false,
     setPassError: true,
     fieldSet: false,
    page_error: false,
    ch_error: "",
    page_message: false,
    message: "",
    loca: this.props.loca,
    isMobile: this.props.isMobile,
    flag: true,
    isStrongPassword: true,
    isConfirmFilled: false,
  };

  constructor(props) {
    super(props);
    this.checkPasswordStrength = this.checkPasswordStrength.bind(this);
    this.change_password = this.change_password.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.setConfirmPassword = this.setConfirmPassword.bind(this);
    this.logincall = this.logincall.bind(this);
    this.setFlag = this.setFlag.bind(this);
  }

  componentDidMount() {}

  static getDerivedStateFromProps(props, state) {
    if (props.isMobile !== state.isMobile) {
      return {
        isMobile: props.isMobile,
      };
    }
    return null;
  }

  logincall() {
    // this.props.showLogin();
    this.props.navigate("/");
  }

  checkPasswordStrength(password) {
    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return strongPasswordRegex.test(password);
    // this.props.showLogin();
    this.props.navigate("/");
  }

  change_password() {
    var pas_wrd = this.state.password;
    if (this.state.page_error === false && this.state.ch_error === "") {
      if (this.state.password !== "" && this.state.confirmPassword !== "") {
        if (this.state.password === this.state.confirmPassword) {
          let email = localStorage.getItem("email");
          let fp = { forget_password: { username: email, password: pas_wrd } };
          axios
            .post(this.state.loca + "/loom/set/createnewpassword", fp, {})
            .then(
              (resp) => {
                let cnp = resp.data;
                if ("Error" in cnp) {
                  this.setState({
                    page_error: true,
                    ch_error: cnp.Error,
                  });
                } else {
                  this.setState({
                    page_message: true,
                    message: cnp.Success,
                    fieldSet: false,
                    error: false,
                  });
                  //  this.props.showLogin();
                  this.props.navigate("/");
                }
              },
              (error) => {
                this.props.showErrorCompo();
              }
            );
        } else {
          this.setState({
            page_error: true,
            ch_error: "Password missmatch",
          });
        }
      } else {
        this.setState({
          page_error: true,
          ch_error: "Enter password or confirm password",
        });
      }
    }
  }

  // setPassword(e) {
  //   if (this.state.confirmPassword !== "") {
  //     if (e !== this.state.confirmPassword) {
  //       this.setState({ error: true, fieldSet: true });
  //     } else {
  //       this.setState({ error: false, fieldSet: true });
  //     }
  //   }
  //   this.setState({ password: e, page_error: false, ch_error: "" });
  // }

  setPassword(value) {
    if (value === "") {
      this.setState({
        page_error: false,
        ch_error: "",
        password: value,
        fieldSet: false,
        setPassError: true,
      });
    } else {
      if (this.state.confirmPassword !== "") {
        if (value !== this.state.confirmPassword) {
          this.setState({ error: true, fieldSet: true });
        } else {
          this.setState({ error: false, fieldSet: true });
        }
      }
      this.setState({ password: value, page_error: false, ch_error: "" });
      let err = this.validatePassword(value);
      if (err !== "") {
        this.setState({ page_error: true, ch_error: err });
        this.state.setPassError = true;
      } else {
        this.state.setPassError = false;
            }
    }
  }

  validatePassword(password) {
    const isValidLength = validator.isLength(password, { min: 8 });
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (!isValidLength) return "Password must be at least 8 characters long.";
    if (!hasUpperCase)
      return "Password must contain at least one uppercase letter.";
    if (!hasLowerCase)
      return "Password must contain at least one lowercase letter.";
    if (!hasNumber) return "Password must contain at least one number.";
    if (!hasSpecialChar)
      return "Password must contain at least one special character.";

    return "";
  }

  setConfirmPassword(e) {
    const isConfirmFilled = e !== "";
    var frst_passwrd = this.state.password;
    // if (frst_passwrd.length > 7) {
    if (frst_passwrd !== "") {
      if (this.state.password === e) {
        this.setState({
          error: false,
          confirmPassword: e,
          fieldSet: true,
          isConfirmFilled,
        });
      } else {
        this.setState({
          error: true,
          confirmPassword: e,
          fieldSet: true,
          isConfirmFilled,
        });
      }
    } else {
      this.setState({
        fieldSet: false,
        page_error: true,
        ch_error: "Firstly fill password",
        isConfirmFilled,
      });
    }
    // } else {
    //   this.setState({page_error: true, ch_error: "Enter at least 4 characters"});
    // }
  }

  setFlag(e) {
    this.setState({ flag: !e.target.checked });
  }

  render() {
    return (
      <div className="container  login_container">
        <div
          style={{ flexGrow: 1, alignItems: "center", height: "100vh" }}
          className="d-flex justify-content-center"
        >
          <Card
            className="carrd"
            style={{
              background: "grey",
              // marginTop: "120px",
              borderRadius: "8px",
            }}
          >
            <CardHeader style={{ background: "grey" }}>
              <h3>Generate New Pin</h3>
            </CardHeader>

            {this.state.page_error === true && (
              <div
                className="alertgp alert-danger"
                style={{ color: "black", margin: "10px" }}
              >
                {this.state.ch_error}
              </div>
            )}

            {this.state.page_message === true && (
              <div
                className="alertgp alert-success"
                style={{ color: "black", margin: "10px" }}
              >
                {this.state.message}
              </div>
            )}

            <CardBody className="cardchild" style={{ background: "grey" }}>
              <div style={{ textAlign: "center", padding: "15px" }}>
                <i
                  className="fa fa-user"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Profile"
                  aria-hidden="true"
                  style={{
                    color: "white",
                    border: "2.5px solid white",
                    height: "4.5rem",
                    width: "4.5rem",
                    fontSize: "76px",
                    textAlign: "center",
                    borderRadius: "39px",
                    marginTop: "-49",
                  }}
                ></i>
              </div>
              <div className=" usericpadd input-group form-group pb-2">
                <input
                  type={this.state.flag ? "password" : "text"}
                  name="password"
                  value={this.state.password}
                  placeholder="New Password"
                  className="inpp "
                  onChange={(e) => this.setPassword(e.target.value)}
                ></input>
              </div>

              <div className=" usericpadd input-group form-group pb-2">
                <input
                  type={this.state.flag ? "password" : "text"}
                  name="password"
                  value={this.state.confirmPassword}
                  placeholder="Confirm Password"
                  className=" inpp "
                  onChange={(e) => this.setConfirmPassword(e.target.value)}
                  disabled={this.state.setPassError}
                ></input>
              </div>
              {this.state.error &&
                this.state.fieldSet &&
                !this.state.setPassError &&
                this.state.isConfirmFilled && (
                <div
                  className="alertgp alert-danger"
                  style={{ color: "black" }}
                >
                    Password Mismatch.
                </div>
              )}

              {!this.state.error &&
                this.state.fieldSet &&
                !this.state.setPassError &&
                this.state.isConfirmFilled && (
                <div
                  className="alertgp alert-success"
                  style={{ color: "black" }}
                >
                  Password Matched.
                </div>
                )}
              <div
                className=" row align-items-center  justify-content-center remember "
                style={{ fontSize: "16px", color: "white" }}
              >
                Show Password
                <input
                  type="checkbox"
                  checked={!this.state.flag}
                  onChange={(e) => {
                    this.setFlag(e);
                  }}
                  aria-hidden="true"
                />
              </div>
              <div className="form-group" style={{ marginTop: "22px" }}>
                <input
                  type="button"
                  value="Submit"
                  className="btn btnyellow btn-hover"
                  style={{ backgroundColor: "#ffc312", fontWeight: "500" }}
                  onClick={this.change_password}
                ></input>
              </div>
            </CardBody>

            {/* <CardFooter style={{ background: "grey" }}>
            <div className="d-flex links">
              <i
                className="fa fa-arrow-left"
                style={{ color: "black", marginTop: "6px" }}
              ></i>
              <a href="/#" onClick={this.logincall} style={{ color: "black" }}>
                Re-Login
              </a>
            </div>
          </CardFooter> */}
          </Card>
        </div>
      </div>
    );
  }
}

export default WithNavigate(GenerateNewPin);
