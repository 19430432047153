import React, { Component } from "react";
import axios from "axios";
import "../css/login.css";
import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { message } from "antd";
import WithNavigate from "./withNavigate";
import validator from "validator";

class SetNewPassword extends Component {
  state = {
    password: "",
    newPassword: "",
    oldPassword: "",
    confirmPassword: "",
    flag: false,
    errorMsg: [],
    op: "",
    un: "",
    loca: this.props.loca,
    isMobile: this.props.isMobile,
    page_message: false,
    message: "",
    showHideflag: true,
    page_error: false,
    ch_error: "",
    page_message: false,
    isStrongPassword: true,
    error: false,
    setPassError: true,
    fieldSet: false,
    isConfirmFilled: false,
  };

  constructor(props) {
    super(props);
    this.logincall = this.logincall.bind(this);
    this.updateCall = this.updateCall.bind(this);
    this.setNewPassword = this.setNewPassword.bind(this);
    this.setConfirmPassword = this.setConfirmPassword.bind(this);
    this.setOldPassword = this.setOldPassword.bind(this);
    this.checkPasswordStrength = this.checkPasswordStrength.bind(this);
    this.setFlag = this.setFlag.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const { op, un } = this.props.location.state;
    this.setState({ op: op, un: un });
    if (this.state.op === undefined || this.state.un === undefined) {
      this.logincall();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location } = props;
    if (props.isMobile !== state.isMobile) {
      return {
        isMobile: props.isMobile,
      };
    }
    return null;
  }

  logincall() {
    //this.props.showLogin();
    this.props.navigate("/login");
  }

  updateCall() {
    let eMsg = [];
     this.state.errorMsg = [];
    if (this.state.password !== "" && this.state.confirmPassword !== "") {
      if (
        this.state.flag === false &&
        this.state.errorMsg.length === 0 &&
        this.state.error === false
      ) {
        if (this.state.op === this.state.oldPassword) {
          this.setState({ errorMsg: [], flag: false });
        } else {
          eMsg.push("Old password is wrong! ");
          this.setState({ flag: true });
        }
        if (this.state.error === "") {
          if (this.state.password === this.state.confirmPassword) {
            this.setState({ errorMsg: [], flag: false });
          } else {
            eMsg.push("NewPassword and ConfirmPassword does not match! ");
            this.setState({ flag: true });
          }
        }

        if (!eMsg.length > 0) {
          let json = {
            username: this.state.un,
            password: this.state.password,
          };

          axios
            .post(this.state.loca + "/loom/set/newpassword", json, {
              headers: {
                "Content-Type": "application/json",

                /*  authorization: "Bearer " + token, */
              },
            })
            .then(
              (resp) => {
                let result = resp.data;
                console.log(result);
                if ("Error" in result) {
                  this.setState({
                    flag: true,
                    errorMsg: result.Error,
                    message: "",
                  });
                } else {
                  // if (resp.data.newPasswordSet) {
                  //   this.props.showLogin();
                  // }
                  this.setState({
                    page_message: true,
                    message: result.Success,
                    flag: false,
                    errorMsg: [],
                  });
                  // this.props.showLogin();
                  this.props.navigate("/login");
                }
              },
              (error) => {
                this.props.showErrorCompo();
              }
            );
        } else {
          this.setState({
            errorMsg: eMsg,
            flag: true,
            message: "",
            page_message: false,
          });
        }
      }
    } else {
      // eMsg.push("NewPassword and ConfirmPassword is empty! ");
      this.setState({
        errorMsg: "NewPassword and ConfirmPassword is empty!",
        flag: true,
        message: "",
        page_message: false,
      });
    }
  }

  checkPasswordStrength(password) {
    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return strongPasswordRegex.test(password);
  }

  setOldPassword(e) {
    this.setState({ oldPassword: e, flag: false });
  }

  // setNewPassword(e) {
  //   this.setState({ newPassword: e, flag: false });
  //   let err = this.validatePassword(e);
  //   if (err !== "") {
  //     this.setState({ flag: true, errorMsg: err });
  //   }
  // }

  setNewPassword(value) {
    if (value === "") {
      this.setState({
        page_error: false,
        ch_error: "",
        password: value,
        fieldSet: false,
        setPassError: true,
      });
    } else {
      if (this.state.confirmPassword !== "") {
        if (value !== this.state.confirmPassword) {
          this.setState({ error: true, fieldSet: true });
        } else {
          this.setState({ error: false, fieldSet: true });
        }
      }
      this.setState({
        password: value,
        page_error: false,
        ch_error: "",
        flag: false,
        errorMsg: [],
      });
      let err = this.validatePassword(value);
      if (err !== "") {
        this.setState({ page_error: true, ch_error: err });
        this.state.setPassError = true;
      } else {
        this.state.setPassError = false;
      }
    }
  }
  validatePassword(password) {
    const isValidLength = validator.isLength(password, { min: 8 });
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (!isValidLength) return "Password must be at least 8 characters long.";
    if (!hasUpperCase)
      return "Password must contain at least one uppercase letter.";
    if (!hasLowerCase)
      return "Password must contain at least one lowercase letter.";
    if (!hasNumber) return "Password must contain at least one number.";
    if (!hasSpecialChar)
      return "Password must contain at least one special character.";

    return "";
  }

  // setConfirmPassword(e) {
  //   this.setState({ confirmPassword: e, flag: false });
  // }

  setConfirmPassword(e) {
    const isConfirmFilled = e !== "";
    var frst_passwrd = this.state.password;
    // if (frst_passwrd.length > 7) {
    if (frst_passwrd !== "") {
      if (this.state.password === e) {
        this.setState({
          error: false,
          confirmPassword: e,
          fieldSet: true,
          isConfirmFilled,
          flag: false,
          errorMsg: [],
        });
      } else {
        this.setState({
          error: true,
          confirmPassword: e,
          fieldSet: true,
          isConfirmFilled,
          flag: false,
          errorMsg: [],
        });
      }
    } else {
      this.setState({
        fieldSet: false,
        page_error: true,
        ch_error: "Firstly fill password",
        isConfirmFilled,
      });
    }
  }

  setFlag(e) {
    this.setState({ showHideflag: !e.target.checked });
  }
  // borderRadius: "8px",
  render() {
    console.log("isConfirmFilled", this.state.isConfirmFilled);
    return (
      <div className="container  login_container">
        <div
          style={{ flexGrow: 1, alignItems: "center", height: "100vh" }}
          className="d-flex justify-content-center"
        >
          <Card
            className="carrd"
            style={{
              background: "grey",
              // marginTop: "97px",
            }}
          >
            <CardHeader style={{ background: "grey" }}>
              <h3>Set New Password</h3>
            </CardHeader>
            {this.state.flag === true && (
              <div
                className="alertgp alert-danger"
                style={{ color: "black", margin: "10px" }}
              >
                {this.state.errorMsg}
              </div>
            )}
            {this.state.page_message === true && (
              <div
                className="alertgp alert-success"
                style={{ color: "black", margin: "10px" }}
              >
                {this.state.message}
              </div>
            )}
            {this.state.page_error === true && (
              <div
                className="alertgp alert-danger"
                style={{ color: "black", margin: "10px" }}
              >
                {this.state.ch_error}
              </div>
            )}

            {this.state.page_message === true && (
              <div
                className="alertgp alert-success"
                style={{ color: "black", margin: "10px" }}
              >
                {this.state.message}
              </div>
            )}
            <CardBody className="cardchild" style={{ background: "grey" }}>
              <div style={{ textAlign: "center", padding: "15px" }}>
                <i
                  className="fa fa-user"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Profile"
                  style={{
                    color: "white",
                    border: "2.5px solid white",
                    height: "4.5rem",
                    width: "4.5rem",
                    fontSize: "62px",
                    textAlign: "center",
                    borderRadius: "39px",
                    marginTop: "-49",
                  }}
                ></i>
              </div>

              <div className=" usericpadd input-group form-group pb-2">
                <input
                  type={this.state.showHideflag ? "password" : "text"}
                  name="password"
                  placeholder="Oldpassword"
                  className=" inpp "
                  value={this.state.oldPassword}
                  onChange={(e) => {
                    this.setOldPassword(e.target.value);
                  }}
                ></input>
              </div>
              <div className=" usericpadd input-group form-group pb-2">
                <input
                  type={this.state.showHideflag ? "password" : "text"}
                  name="password"
                  placeholder="NewPassword"
                  className=" inpp "
                  // value={this.state.newPassword}
                  value={this.state.password}
                  onChange={(e) => {
                    this.setNewPassword(e.target.value);
                  }}
                ></input>
              </div>

              <div className=" usericpadd input-group form-group">
                <input
                  type={this.state.showHideflag ? "password" : "text"}
                  name="password"
                  placeholder="ConfirmPassword"
                  className=" inpp"
                  value={this.state.confirmPassword}
                  onChange={(e) => {
                    this.setConfirmPassword(e.target.value);
                  }}
                  disabled={this.state.setPassError}
                ></input>
              </div>
              {this.state.error === true &&
                this.state.fieldSet === true &&
                this.state.setPassError === false &&
                this.state.isConfirmFilled && (
                  <div
                    className="alertgp alert-danger mt-2"
                    style={{ color: "black" }}
                  >
                    Password Missmatch.
                  </div>
                )}

              {this.state.error === false &&
                this.state.fieldSet === true &&
                this.state.setPassError === false &&
                this.state.isConfirmFilled && (
                  <div
                    className="alertgp alert-success mt-2"
                    style={{ color: "black" }}
                  >
                    Password Matched.
                  </div>
                )}
              <div
                className=" row align-items-center  justify-content-center remember "
                style={{ fontSize: "16px", color: "white" }}
              >
                Show Password
                <input
                  type="checkbox"
                  checked={!this.state.showHideflag}
                  onChange={(e) => {
                    this.setFlag(e);
                  }}
                  aria-hidden="true"
                />
              </div>

              {/* {this.state.flag &&
                this.state.errorMsg.map((obj, index) => (
                  <div key={index} style={{ color: "white", marginTop: "8px" }}>
                    {obj}
                  </div>
                ))} */}
              <div className="form-group" style={{ marginTop: "22px" }}>
                <button
                  className="btn btnyellow"
                  style={{ backgroundColor: "#ffc312", fontWeight: "500" }}
                  onClick={() => this.updateCall()}
                >
                  Update
                </button>
              </div>
            </CardBody>

            {/* 
            <CardFooter style={{ background: "grey" }}>
              <div className="d-flex links">
                <i
                  className="fa fa-arrow-left"
                  style={{ color: "black", marginTop: "6px" }}
                ></i>
                <a
                  href="/#"
                  onClick={this.logincall}
                  style={{ color: "black" }}
                >
                  Login
                </a>
              </div>
            </CardFooter> */}
          </Card>
        </div>
      </div>
    );
  }
}

export default WithNavigate(SetNewPassword);
